<template>
 <div>
  <el-dialog :title="type?'门牌修改':'门牌录入'" :close-on-click-modal="false" top="5%" :visible.sync="showDialog" width="40%">
   <el-form :model="addform" ref="addform" :rules="myrules" label-width="130px">
    <el-form-item label="门牌-UUID" prop="uuid">
     <el-input v-model.trim="addform.uuid" placeholder="请输入门牌UUID"></el-input>
    </el-form-item>
    <el-form-item label="门显" prop="panelType">
     <el-select v-model="addform.panelType" clearable filterable placeholder="请选择">
      <el-option v-for="(item,index) in panelTypes" :key="index" :label="item.label" :value="item.value"></el-option>
     </el-select>
    </el-form-item>
    <el-form-item label="PMS门店编号" prop="pmsHotelId">
     <el-input v-model.trim="addform.pmsHotelId" placeholder="请输入hotelId"></el-input>
    </el-form-item>
    <el-form-item label="门牌号" :prop="addform.panelType==0?'pmsRoomId':''" >
     <el-input v-model.trim="addform.pmsRoomId" placeholder="请输入门牌号码"></el-input>
    </el-form-item>
    <el-form-item label="RCU-UUID" prop="mainboardId" v-if="addform.panelType==0">
     <el-input v-model.trim="addform.mainboardId" placeholder="请输入设备id"></el-input>
    </el-form-item>
    <el-form-item label="MAC" prop="mac">
     <el-input v-model.trim="addform.mac" placeholder="请输入MAC地址"></el-input>
    </el-form-item>
    <el-form-item label="IP" prop="ip">
     <el-input v-model.trim="addform.ip" placeholder="请输入IP地址"></el-input>
    </el-form-item>

    <el-form-item label="地址" prop="address">
     <el-input v-model.trim="addform.address" placeholder="请输入地址"></el-input>
    </el-form-item>
    <el-form-item label="门牌电话" prop="displayPhone">
     <el-input v-model.trim="addform.displayPhone" placeholder="请输入门牌电话"></el-input>
    </el-form-item>

   </el-form>
   <div slot="footer" class="dialog-footer">
    <el-button @click="showDialog=false">取 消</el-button>
    <el-button type="primary" @click="confirmAdd('addform')" :loading="loading">确 定</el-button>
   </div>
  </el-dialog>
 </div>
</template>
<script>
import { panelAdd, panelEdit } from "@/api";
export default {
 props: ["panelTypes"],
 data() {
  return {
   loading: false,
   showDialog: false,
   addform: {
    uuid: null,
    pmsHotelId: null,
    pmsRoomId: null,
    mac: null,
    ip: null,
    address: null,
    displayPhone: null,
    mainboardId: null,
    panelType: null, //0:房间门显,1:公区门显
   },
   type: 0,
   myrules: {
    // displayPhone: [{ required: true, message: "请输入", trigger: ["blur"] }],
    pmsHotelId: [{ required: true, message: "请输入", trigger: ["blur"] }],
    pmsRoomId: [{ required: true, message: "请输入", trigger: ["blur"] }],
    mac: [{ required: true, message: "请输入", trigger: ["blur"] }],
    //  ip: [{ required: true, message: "请输入", trigger: ["blur"] }],
    // mainboardId: [{ required: true, message: "请输入", trigger: ["blur"] }],
    panelType: [{ required: true, message: "请输入", trigger: ["blur"] }],
   },
  };
 },

 methods: {
  confirmAdd(formName) {
   this.$refs[formName].validate((valid) => {
    if (valid) {
     console.log(this.addform);
     let data = this.getData();
     console.log(data);
     if (this.type == 0) {
      this.addPanelReq(data);
     } else {
      data.doorPanelId = this.addform.doorPanelId;
      this.editPanelReq(data);
     }
    } else {
     return false;
    }
   });
  },
  getData() {
   let data = new Object();
   [
    "uuid",
    "panelType",
    "pmsHotelId",
    "mac",
    "ip",
    "address",
    "displayPhone",
    "pmsRoomId"
   ].forEach((key) => {
    data[key] = this.addform[key];
   });
   if (this.addform.panelType == 0) {
    data.mainboardId = this.addform.mainboardId;
    // data.pmsRoomId = this.addform.pmsRoomId;
   }
   return data;
  },
  addPanelReq(data) {
   this.loading = true;
   panelAdd(data)
    .then((res) => {
     this.loading = false;
     if (res.data.code == "000000") {
      this.$message.success("添加成功");
      this.resetForm();
      this.$refs.addform.resetFields();
      this.showDialog = false;
      this.$parent.getPage();

      //   this.$parent.getList();
     } else {
      this.$message.error(res.data.message);
     }
     console.log(res);
    })
    .catch((err) => {
     this.loading = false;
     console.log(err);
    });
  },
  editPanelReq(data) {
   this.loading = true;
   panelEdit(data)
    .then((res) => {
     this.loading = false;
     if (res.data.code == "000000") {
      this.$message.success("修改成功");
      this.resetForm();
      this.$refs.addform.resetFields();
      this.showDialog = false;
      this.$parent.getList();
     } else {
      this.$message.error(res.data.message);
     }
     console.log(res);
    })
    .catch((err) => {
     this.loading = false;
     console.log(err);
    });
  },
  recordedSet(data) {
   this.type = 0;
   this.showDialog = true;
   this.addform = Object.assign({}, data);
  },
  openSet(data) {
   this.showDialog = true;
   if (data) {
    this.type = 1;
    this.addform = Object.assign({}, data);
    console.log(this.addform, "this.addform");
   } else {
    if (this.type == 1) {
     setTimeout(() => {
      this.resetForm();
      this.$refs.addform.resetFields();
     }, 300);
    }
    this.type = 0;
   }
  },
  resetForm() {
   this.addform = {
    uuid: null,
    pmsHotelId: null,
    pmsRoomId: null,
    mac: null,
    ip: null,
    address: null,
    displayPhone: null,
    mainboardId: null,
    panelType: null, //0:房间门显,1:公区门显
   };
  },
 },
};
</script>
<style lang="scss" scoped>
</style>


