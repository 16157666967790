var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.type ? "门牌修改" : "门牌录入",
            "close-on-click-modal": false,
            top: "5%",
            visible: _vm.showDialog,
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "addform",
              attrs: {
                model: _vm.addform,
                rules: _vm.myrules,
                "label-width": "130px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "门牌-UUID", prop: "uuid" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入门牌UUID" },
                    model: {
                      value: _vm.addform.uuid,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addform,
                          "uuid",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addform.uuid",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "门显", prop: "panelType" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: {
                        clearable: "",
                        filterable: "",
                        placeholder: "请选择",
                      },
                      model: {
                        value: _vm.addform.panelType,
                        callback: function ($$v) {
                          _vm.$set(_vm.addform, "panelType", $$v)
                        },
                        expression: "addform.panelType",
                      },
                    },
                    _vm._l(_vm.panelTypes, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { label: item.label, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "PMS门店编号", prop: "pmsHotelId" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入hotelId" },
                    model: {
                      value: _vm.addform.pmsHotelId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addform,
                          "pmsHotelId",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addform.pmsHotelId",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "门牌号",
                    prop: _vm.addform.panelType == 0 ? "pmsRoomId" : "",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入门牌号码" },
                    model: {
                      value: _vm.addform.pmsRoomId,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addform,
                          "pmsRoomId",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addform.pmsRoomId",
                    },
                  }),
                ],
                1
              ),
              _vm.addform.panelType == 0
                ? _c(
                    "el-form-item",
                    { attrs: { label: "RCU-UUID", prop: "mainboardId" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入设备id" },
                        model: {
                          value: _vm.addform.mainboardId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.addform,
                              "mainboardId",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "addform.mainboardId",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-form-item",
                { attrs: { label: "MAC", prop: "mac" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入MAC地址" },
                    model: {
                      value: _vm.addform.mac,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addform,
                          "mac",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addform.mac",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "IP", prop: "ip" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入IP地址" },
                    model: {
                      value: _vm.addform.ip,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addform,
                          "ip",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addform.ip",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "地址", prop: "address" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入地址" },
                    model: {
                      value: _vm.addform.address,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addform,
                          "address",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addform.address",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "门牌电话", prop: "displayPhone" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入门牌电话" },
                    model: {
                      value: _vm.addform.displayPhone,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.addform,
                          "displayPhone",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "addform.displayPhone",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function ($event) {
                      return _vm.confirmAdd("addform")
                    },
                  },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }