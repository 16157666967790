<template>
  <div class="my-box" v-loading="loading">
    <!-- 功能区域 -->
    <el-row>
      <el-card shadow="always">
        <el-form
          label-width="80px"
          class="topS"
          ref="seekData"
          :model="seekData"
        >
          <el-row :gutter="10">
            <el-col :span="4">
              <el-button @click="openpopup" v-if="showAdd">新增</el-button>
              <label class="control-label file" v-if="showAdd">
              <input
                type="file"
                class="upload"
                @change="importExcel"
                ref="inputer"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              导入excel
            </label>
              <el-button @click="getNoRecorded">查看未录入门牌</el-button>
            </el-col>
            <el-col :span="4">
              <el-form-item label="门显" prop="panelType">
                <el-select
                  v-model="seekData.panelType"
                  clearable
                  filterable
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) in panelTypes"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="门牌-UUID" prop="uuid">
                <el-input
                  v-model.trim="seekData.uuid"
                  placeholder="请输入"
                  maxlength="15"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item
                label="PMS门店编号"
                prop="pmsHotelId"
                label-width="120px"
              >
                <el-input
                  v-model.trim="seekData.pmsHotelId"
                  placeholder="请输入"
                  maxlength="15"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item label="门牌号" prop="pmsRoomId">
                <el-input
                  v-model.trim="seekData.pmsRoomId"
                  placeholder="请输入"
                  maxlength="15"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2" class="reset-button">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button @click="reset">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
    </el-row>
    <!-- 内容区域 -->
    <el-row>
      <el-card shadow="always">
        <div class="my-header-table">
          <el-table
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            :height="height"
            style="width: 100%"
          >
            <!-- <el-table-column type="selection" width="55"></el-table-column> -->
            <el-table-column
              prop="pmsHotelId"
              label="PMS门店编号"
            ></el-table-column>
            <el-table-column prop="pmsRoomId" label="门牌号"></el-table-column>
            <el-table-column prop="uuid" label="门牌-UUID"></el-table-column>
            <el-table-column prop="panelType" label="门显类型">
              <template slot-scope="scope">
                <div>
                  {{ showLabel(scope.row.panelType) }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="mainboardId"
              label="RCU-UUID"
              width="200"
            ></el-table-column>
            <el-table-column prop="ip" label="内网IP"></el-table-column>

            <el-table-column prop="address" label="地址"></el-table-column>
            <el-table-column prop="mac" label="MAC"></el-table-column>

            <el-table-column prop="status" label="状态">
              <template slot-scope="scope">
                <div>
                  {{ showStatus(scope.row.status) }}
                </div>
              </template>
            </el-table-column>

            <!-- 操作按钮列 -->
            <el-table-column label="操作" width="280">
              <template slot-scope="scope">
                <el-button
                  v-if="showSync && listType == 0"
                  type="success"
                  size="mini"
                  @click="handleSync(scope.row)"
                >
                  同步
                </el-button>
                <!-- 编辑按钮 -->
                <el-button
                  v-if="showUpdate && listType == 0"
                  type="primary"
                  size="mini"
                  @click="handleEdit(scope.row)"
                >
                  修改
                </el-button>
                <!-- 删除按钮 -->
                <el-button
                  v-if="showDelete && listType == 0"
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.row)"
                  >删除</el-button
                >
                <el-button
                  v-if="showUpdate && listType == 1"
                  type="primary"
                  size="mini"
                  @click="recordedSet(scope.row)"
                >
                  录入
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            v-if="listType == 0"
            background
            layout="prev, pager, next, sizes, total, jumper"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="seekData.pageSize"
            :current-page="seekData.pageNum"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </div>
      </el-card>
    </el-row>

    <addView ref="add" :panelTypes="panelTypes" />
  </div>
</template>
<script>
import {
  delBrand,
  getPanelNotSet,
  getBrandSelect,
  getPanelList,
  //  getHotelSelect,
  panelDetele,
  panelSync,
  panelImport
} from "@/api";
import { showLabel } from "@/api/common";
import addView from "./addView";

export default {
  components: {
    addView,
  },
  // name:"pp",
  data() {
    return {
      brandSelectData: null,
      panelTypes: [
        {
          value: 0,
          label: "房间门显",
        },
        {
          value: 1,
          label: "公区门显",
        },
        {
          value: 2,
          label: "核销门显",
        },
        {
          value: 3,
          label: "电梯摄像头",
        },
        {
          value: 4,
          label: "公区摄像头",
        },
      ],
      loading: false,
      // 品牌列表数据
      tableData: [],
      // 查询的数据
      total: null,
      seekData: {
        uuid: null,
        panelType: null,
        pmsRoomId: null,
        pmsHotelId: null,
      },
      pageSize: 10,
      pageNum: 1,
      hotelSelectDatas: [],

      query: {},
    };
  },
  beforeMount() {
    let h = document.documentElement.clientHeight || document.body.clientHeight;
    this.height = h - 240;
  },

  computed: {
    // 权限
    showAdd() {
      return this.$store.state.roleMenu.includes("door:panel:add");
    },
    showDelete() {
      return this.$store.state.roleMenu.includes("door:panel:delete");
    },
    showUpdate() {
      return this.$store.state.roleMenu.includes("door:panel:edit");
    },
    showSync() {
      return this.$store.state.roleMenu.includes("door:panel:sync");
    },
  },
  created() {
    // 调用初始化表格数据方法
    this.initialize();
    this.getList();
  },
  methods: {
    initialize() {
      getBrandSelect().then((res) => {
        if (res.status === 200) {
          this.brandSelectData = res.data.data;
        }
      });
    },
    // 选择框
    // selectOnes(id) {
    //  this.seekData.hotelId = null;
    //  this.seekData.buildingId = null;
    //  this.seekData.floorId = null;
    //  getHotelSelect(id).then((res) => {
    //   this.hotelSelectDatas = res.data.data;
    //  });
    // },

    showStatus(status) {
      if (status === 1) {
        return "在线";
      } else {
        return "离线";
      }
    },
    openpopup() {
      this.$refs.add.openSet();
    },
    // 导入excel
    importExcel(event) {
      let inputDOM = this.$refs.inputer;
      // 通过DOM取文件数据
      // this.fil = inputDOM.files;
      console.log(inputDOM.files[0]);
      let formData = new FormData();
      formData.append("file", inputDOM.files[0]);
      const loading = this.$loading({
        lock: true,
        text: "上传中，请稍等...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      
      panelImport(formData).then((res) => {
        console.log(res)
        loading.close();
        this.$message.info(res.data.message);
      });
    },
    recordedSet(row) {
      this.$refs.add.recordedSet(row);
    },
    getPage() {
      if (this.listType == 1) {
        this.getNoRecorded();
      } else {
        this.getList();
      }
    },
    getNoRecorded() {
      this.loading = true;
      this.listType = 1;
      getPanelNotSet()
        .then((res) => {
          this.loading = false;
          if (res.status === 200) {
            this.tableData = res.data.data;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 编辑品牌
    handleEdit(row) {
      this.$refs.add.openSet(row);
    },
    showLabel(type) {
      return showLabel(type, this.panelTypes);
    },

    // 初始化表格数据
    getList() {
      this.loading = true;
      this.listType = 0;
      getPanelList(this.pageNum, this.pageSize, this.query)
        .then((res) => {
          this.loading = false;
          if (res.status === 200) {
            this.tableData = res.data.data.rows;
            this.total = res.data.data.total;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    handleSync(row) {
      this.$confirm("此操作将同步该门牌, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.syncReq(row.doorPanelId);
        })
        .catch(() => {});
    },
    syncReq(id) {
      this.loading = true;
      panelSync(id)
        .then((res) => {
          this.loading = false;
          if (res.data.code == "000000") {
            this.$message.success("同步成功");
            this.getList();
          } else {
            this.$message.error("同步失败");
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // 删除品牌
    handleDelete(row) {
      // console.log(row)
      this.$confirm("此操作将删除该门牌, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.delReq(row.doorPanelId);
        })
        .catch(() => {});
    },

    delReq(id) {
      this.loading = true;
      panelDetele(id)
        .then((res) => {
          this.loading = false;
          if (res.data.code == "000000") {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error("删除失败");
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // 查询按钮
    handleSearch() {
      this.pageNum = 1;
      this.query = Object.assign({}, this.seekData);
      console.log(this.query);
      this.getList();
    },
    // 重置按钮
    reset() {
      this.pageNum = 1;
      this.$refs.seekData.resetFields();
      this.handleSearch();
    },
    //分页
    handleCurrentChange(pageNum) {
      this.pageNum = pageNum;
      this.getList();
    },
    handleSizeChange(psize) {
      this.pageSize = psize;
      this.pageNum = 1;
      this.getList();
    },
  },
};
</script>
<style lang="scss" scoped>
.my-box {
  .el-row {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-col {
    border-radius: 4px;
  }
  .my-input {
    max-width: 209px;
  }
}
.checkBox {
  display: inline-block;
  width: 28%;
}

.file {
  position: relative;
  padding: 9px 15px;
  margin-left: 10px;
  font-size: 12px;
  border-radius: 3px;
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
}
.file input {
  position: absolute;
  font-size: 18px;
  right: 0;
  top: 0;
  opacity: 1;
  z-index: -1;
  border: 1px solid black;
}
</style>
